import { Box, Grid, Heading, Image, Link, Progress } from "@chakra-ui/react"
import { BlockOrderCompleteEntityHydrated } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { useContentCrossSell } from "~/hooks/useContentCrossSell"
import FormHeading from "../checkout/FormHeading"

interface Props {
  config: BlockOrderCompleteEntityHydrated
  upsellContentId: string
}

const OrderCompleteUpsell: React.FC<Props> = ({ config, upsellContentId }) => {
  const { t } = useTranslation()
  const { crossSell, isLoading } = useContentCrossSell(upsellContentId)

  return (
    <Box>
      {isLoading && <Progress size="sm" isIndeterminate my={8} />}
      {crossSell.length > 0 && (
        <>
          <FormHeading
            id="upsell-title"
            fontSize={{ base: "xl", md: "xl", "2xl": "2xl" }}
            color="gray.800"
            mt={{ base: 0, lg: 3 }}
          >
            {config.upsellTitle ||
              t("components.checkout.OrderCompleteUpsell.PrimaryHeading")}
          </FormHeading>
        </>
      )}

      {!isLoading && (
        <Grid
          templateColumns={{
            base: "repeat(1, minmax(0, 65%))",
            md: "repeat(4, 1fr)",
          }}
          display={{ base: "flex", md: "grid" }}
          overflowX={{ base: "scroll", md: "initial" }}
          gap={{ base: 4, lg: 8 }}
          pb={4}
          pt={0}
        >
          {crossSell.map((crossSell, index) => (
            <Box
              key={index}
              mb="4"
              width={{ base: "65%", md: "100%" }}
              flexShrink={0}
            >
              <Link
                href={crossSell.actionButtonUrl}
                _hover={{ textDecoration: "none" }}
              >
                <Box>
                  <Image
                    src={crossSell.image?.url}
                    alt={crossSell.imageAltText}
                    borderRadius="md"
                    w="100%"
                    boxShadow="sm"
                    transition="boxShadow 1.0s ease-in-out 0.5s"
                    _hover={{
                      boxShadow: "md",
                    }}
                  />
                  <Box>
                    <Heading
                      className="upsell-content-title"
                      as="h4"
                      fontSize={{ base: "md", lg: "lg" }}
                      color="gray.800"
                      my={3}
                    >
                      {crossSell.textTitle}
                    </Heading>
                  </Box>
                </Box>
              </Link>
            </Box>
          ))}
        </Grid>
      )}
    </Box>
  )
}

export default OrderCompleteUpsell
