import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Address } from "~/interfaces/entities/Address"
import { OrderEntity } from "~/interfaces/entities/Order"
import { StoreEntity } from "~/interfaces/entities/Store"
import OrderSummaryAddress from "../checkout/order-summary/OrderSummaryAddress"
import OrderCompleteMap from "./OrderCompleteMap"
import OrderCompleteSummary from "./OrderCompleteSummary"

interface OrderCompleteDetailsProps {
  order: OrderEntity
  orderAddress: Address
  store: StoreEntity
}

const OrderCompleteDetails: React.FC<OrderCompleteDetailsProps> = ({
  order,
  orderAddress,
  store,
  ...rest
}) => {
  const { t } = useTranslation()
  const {
    cart: { fulfillment },
  } = order
  const isPickup = fulfillment === "pickup"

  const [isMapVisible, setIsMapVisible] = useState(false)

  const map = isPickup ? (
    <Box h={52} w="100%" bg="gray.100" boxShadow="md" borderRadius="md" mt={8}>
      <OrderCompleteMap store={store} />
    </Box>
  ) : null // Do not show the map on delivery as we don't have lat/long

  const mobileMap = isPickup ? (
    <>
      <Box>
        <Button
          id="order-summary-view-map"
          variant="link"
          color="blue.500"
          mt={4}
          size="sm"
          onClick={() => setIsMapVisible(show => !show)}
        >
          {t("components.blocks.OrderCompleteBlock.ViewMap")}
        </Button>
      </Box>
      {isMapVisible && map}
    </>
  ) : null

  const mapOfAddress = useBreakpointValue({
    base: mobileMap,
    md: map,
  })

  return (
    <Box
      borderWidth={{ sm: 1 }}
      borderRadius={{ sm: "xl" }}
      px={{ sm: 5 }}
      py={2}
      {...rest}
    >
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textTransform="none" px={0} fontSize="lg" color={"inherit"}>
              {t("components.order-complete.OrderCompleteDetails.OrderSummary")}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr color={"inherit"}>
            <Td px={0}>
              <OrderSummaryAddress address={orderAddress} isPickup={isPickup} />
              {mapOfAddress}
            </Td>
          </Tr>
          <Tr color={"inherit"}>
            <Td px={0} border={{ sm: "none" }}>
              <OrderCompleteSummary
                order={order}
                headingText={t(
                  "components.order-complete.OrderCompleteDetails.OrderItems"
                )}
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  )
}

export default OrderCompleteDetails
