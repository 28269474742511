import { Box, Button, SimpleGrid } from "@chakra-ui/react"
import { BlockOrderCompleteEntityHydrated } from "@jackfruit/common"
import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { useApplicationState } from "~/hooks/useApplicationState"
import { useOrder } from "~/hooks/useOrder"
import { Address } from "~/interfaces/entities/Address"
import OrderCompleteAppInstall from "../order-complete/OrderCompleteAppInstall"
import OrderCompleteDetails from "../order-complete/OrderCompleteDetails"
import OrderCompleteUpsell from "../order-complete/OrderCompleteUpsell"
import Wrapper from "../Wrapper"

export interface Props {
  config: BlockOrderCompleteEntityHydrated
}

const OrderCompleteBlock: React.FC<Props> = ({ config }) => {
  const { t } = useTranslation()
  const { lastOrderId: currentOrderId, blockOrderSummaryAppInstallEnabled } =
    useApplicationState()
  const { order: placedOrder } = useOrder(currentOrderId)

  const { cart, store } = placedOrder
  const { fulfillment, address: cartAddress } = cart

  const isPickup = fulfillment === "pickup"
  const hasUpsell =
    Boolean(config.upsellEnabled) && Boolean(config.upsellContentId)

  const orderAddress: Address = isPickup
    ? {
        name: store?.name,
        line1: store?.address || "",
        line2: store?.addressLine2,
        city: store?.city || "",
        state: store?.state || "",
        country: store?.country || "",
        postcode: store?.postcode || "",
      }
    : cartAddress

  const isFakeOrder = placedOrder.id === 1

  const completeOrderRender = {
    col: { base: 1, md: 2 },
    mx: { lg: 10, "2xl": 28 },
    width: "auto",
    continueBtnAlign: { base: "center", md: "left" },
  }

  const missingAppBannerRender = {
    col: 1,
    mx: "auto",
    width: { base: "full", md: "70%", lg: "50%" },
    continueBtnAlign: "center",
  }

  const colRender = Boolean(blockOrderSummaryAppInstallEnabled)
    ? completeOrderRender
    : missingAppBannerRender

  return (
    <Wrapper pt={0} pb={16} px={4} color="gray.800">
      <Box display={{ base: "none", md: "block" }} mx={{ lg: 10, "2xl": 28 }}>
        {hasUpsell && (
          <Box mt={{ base: "8", md: 0 }}>
            <OrderCompleteUpsell
              config={config}
              upsellContentId={config.upsellContentId}
            />
          </Box>
        )}
      </Box>

      <SimpleGrid
        mx={colRender.mx}
        columns={colRender.col}
        width={colRender.width}
        spacing={4}
        display={{ base: "flex", md: "grid" }}
        flexDirection={{ base: "column-reverse", md: "row" }}
      >
        <Box>
          {!isFakeOrder && (
            <OrderCompleteDetails
              order={placedOrder}
              orderAddress={orderAddress}
              store={store}
            />
          )}

          <Box textAlign={colRender.continueBtnAlign}>
            <Button
              colorScheme="primary"
              color="primary.text"
              id="continue-shopping"
              as={Link}
              to={"/"}
              py={6}
              px={8}
              fontSize={"1.2rem"}
              mb={4}
              mt={6}
              width={{ base: "full", sm: "auto" }}
            >
              {t("components.blocks.OrderCompleteBlock.Continue")}
            </Button>
          </Box>
        </Box>
        <Box>
          {Boolean(blockOrderSummaryAppInstallEnabled) && (
            <OrderCompleteAppInstall />
          )}
        </Box>
      </SimpleGrid>

      <Box display={{ base: "block", md: "none" }}>
        {hasUpsell && (
          <Box mt={{ base: "8", md: 0 }}>
            <OrderCompleteUpsell
              config={config}
              upsellContentId={config.upsellContentId}
            />
          </Box>
        )}
      </Box>
    </Wrapper>
  )
}

export default OrderCompleteBlock
