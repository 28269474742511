import { Box, Flex, useMediaQuery } from "@chakra-ui/react"
import React from "react"
import QRCode from "react-qr-code"
import { useApplicationState } from "~/hooks/useApplicationState"
import AppStoreButtons from "../common/AppStoreButtons"

export interface Props {}

const OrderCompleteAppInstall: React.FC<Props> = () => {
  const {
    iosStoreUrl,
    androidStoreUrl,
    language,
    blockOrderSummaryAppInstallQrCodeUrl,
    blockOrderSummaryAppInstallAppScreenImage,
    blockOrderSummaryAppInstallContent,
  } = useApplicationState()

  // Using object here for props because TS complains that `viewBox` is not assignable, even though it is
  const qrCodeProps = {
    value: blockOrderSummaryAppInstallQrCodeUrl,
    size: 256,
    viewBox: "0 0 256 256",
    style: {
      height: "auto",
      maxWidth: "180px",
      width: "100%",
      margin: "0 auto",
    },
  }

  const isMobileQuery = useMediaQuery([
    "(max-width: 637px)",
    "(min-width: 767px) and (max-width: 1200px)",
  ])

  const isMobile = isMobileQuery.some(queryMatches => queryMatches === true)

  const downloadBgImage = blockOrderSummaryAppInstallAppScreenImage
    ? blockOrderSummaryAppInstallAppScreenImage.path
    : "images/OrderSummaryAppInstallBackground.jpg"
  return (
    <Box
      spacing={0}
      alignItems="stretch"
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius="lg"
      overflow={"hidden"}
      flexDirection={isMobile ? "column" : "row"}
      mb={{ base: 2, md: 8 }}
    >
      {
        <Box
          bgPosition="top"
          bgRepeat="no-repeat"
          bgSize="cover"
          bgImage={downloadBgImage}
          borderRadius={"0.625rem 0.625rem 0 0"}
          w={"full"}
          h={{ base: "204px", md: "300px", lg: "240px", xl: "340px" }}
        />
      }

      <Flex
        alignItems="stretch"
        flexGrow={1}
        justifyContent="center"
        bgColor="#EDF3F9"
        p={4}
        flexDirection={{ base: "column", lg: "row" }}
      >
        <Box
          dangerouslySetInnerHTML={{
            __html: blockOrderSummaryAppInstallContent,
          }}
          py={{ lg: 4 }}
          pr={{ lg: 4 }}
        />

        <Box display={{ base: "block", md: "flex", lg: "none" }} mt={1} mb={3}>
          <AppStoreButtons
            direction="row"
            justify="center"
            appStoreUrl={iosStoreUrl}
            googlePlayUrl={androidStoreUrl}
            language={language}
            isCheckDevice={true}
          />
        </Box>

        <Box
          display={{ base: "none", lg: "block" }}
          width={{ base: "full", md: "20rem", lg: "18.75rem" }}
          borderRadius={"md"}
          py={4}
        >
          <Box
            p={{ base: 3, xl: 4 }}
            w={"full"}
            bgColor={"#ffffff"}
            borderRadius={"md"}
          >
            <QRCode {...qrCodeProps} />
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default OrderCompleteAppInstall
